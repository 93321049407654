<template>
    <div class="container">
      <content-title :nav="nav"></content-title>
      <el-row>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="教练">
            <coachSelect :coachid.sync="coach_id"></coachSelect>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="status"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>

        </el-form>
        <!-- 列表 -->
        <el-table
          :data="DataList"
          :fit="true"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          v-loading="is_loading"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column label="课程名称" prop="title"></el-table-column>
        
          <el-table-column label="总课节数" prop="course_num"></el-table-column>
          <el-table-column label="教学方式" prop="service_type">
              <template slot-scope="{ row }">
                  <p v-if="row.service_type == 1">到店上课</p>
                  <p v-if="row.service_type == 2">上门教学</p>
                  <p v-if="row.service_type == 3">教练场地</p>
              </template>
          </el-table-column>
          <el-table-column label="单节结算价" prop="settlement_cash"></el-table-column>
          <el-table-column
            label="上课人数"
            prop="shopping_course_peoplenum"
          ></el-table-column>
          <el-table-column label="接单时间" prop="create_time" width="160px"></el-table-column>
          <el-table-column label="状态" prop="status">
              <template slot-scope="{ row }">
                 <p>{{options[row.status].label}}</p>
              </template>
          </el-table-column>
          <el-table-column label="操作" width="260px">
            <template slot-scope="{ row }">
              <div class="flex flex-wrap">
                  <el-button type="primary" @click="coachInfo(row)" size="small"
                >查看教练</el-button>
                <el-button type="success" @click="needInfo(row)" size="small"
                >查看需求</el-button>
                    <el-popconfirm
                    v-if="row.status == 0"
                title="确定取消订单这个订单吗？"
               
                @confirm="update(row, 2)"
                >
                <el-button
                type="danger"
                size="small"
                slot="reference"
                >取消订单</el-button
              >
                </el-popconfirm>
   
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="Total"
        ></paging-fy>
      </el-row>
    </div>
  </template>
      
      <script>
  import coachSelect from "../../components/select/coachSelect.vue";
  export default {
    components: {
        coachSelect
    },
    data() {
      return {
        nav: { firstNav: "课程需求管理", secondNav: "教练接单列表" },
        DataList: [],
        is_loading: true,
        currentPage: 1,
        Total: 0,
        status: 0,
        categories:'',
        coach_id:'',
        options: [
          {
            value: 0,
            label: "已接单",
          },
          {
            value: 1,
            label: "已完成",
          },
          {
            value: 2,
            label: "已取消",
          }
        ],
        user_id: "",
        mechanism_id: "",
        price: "",
        listPageChild:[]
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      //需求信息
      needInfo(row) {
        this.$router.push({ name: "courseNeedDetails", query: { id: row.need_id } });
      },
      //查看教练
      coachInfo(row) {
        this.$router.push({ name: "coachDetails", query: { id: row.coach_id } });
      },
      //更新状态
      update(row, status) {
        let url = "/user/coachNeedRecord/updateStatus";
        let data = {
          id: row.id,
          status,
        };
        this.$axios({
          url,
          data,
          method: "post",
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({ message: "成功" });
            this.getList();
          } else {
            this.$message({ message: res.data.message });
          }
        });
      },
      //接单列表
      getList() {
        this.is_loading = true;
        let url = "/user/coachNeedRecord/queryManagerListPage";
        let params = {
          pageSize: 10,
          currentPage: this.currentPage,
          status:this.status,
          coach_id:this.coach_id || null,
        };
        this.$axios
          .get(url, {
            params,
          })
          .then((res) => {
            this.DataList = res.data.data.rows;
            this.Total = res.data.data.total;
            this.is_loading = false;
          });
      },
      search() {
        this.currentPage = 1;
        this.getList();
      },
      changeCurrentPage(v) {
        this.currentPage = v;
        this.getList();
      },
    },
  };
  </script>
      
      <style lang="less">
  .expand-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;
    .el-form-item {
      width: 40%;
    }
  }
  </style>
      